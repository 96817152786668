import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchProjects } from '../../redux/slices/projectsSlice';
import { useTranslation } from 'react-i18next';


function Projects() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const granteeUser = userData?.user?.user_type === 'grantee';

    const projects = useSelector((state) => state.projects.projects);

    useEffect(() => {
        dispatch(fetchProjects()); 
    }, [dispatch]);

    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('projects.projects')}
                {granteeUser &&
                    <span className="ms-3">
                        <Link to={`/create-project`} className="btn btn-primary" type="button">{t('projects.createProject')}</Link>
                    </span>
                }
            </h2>
            <hr className="mb-5"/>
            {projects.length > 0 ? (
                <div className="row">
                    {projects.map((project) => (
                        <div className="col-lg-3 mb-4" key={project.project_id}>
                            <div className="card">
                                <Link to={`/projects/${project.grantee_slug}/${project.project_slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/projects/${project.project_images[0]}`} 
                                        className="card-img-top" 
                                        alt={project.project_name}
                                        onError={(e) => {
                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                        }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{project.project_name}</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-1">
                                                {project.grantee_logo ? (
                                                    <img 
                                                        className="rounded-circle circular-image"
                                                        src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                                        alt={project.project_name}
                                                        onError={(e) => {
                                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                                        }} 
                                                    />
                                                ):(
                                                    <div className="circular-container bg-primary text-white fs-3">
                                                        {project.grantee_name?.charAt(0) || ''}
                                                    </div>

                                                )}   
                                            </div>
                                            {project.grantee_name}
                                        </div>
                                    </div>
                                </Link>   
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{t('projects.noProjectsFound')}</p>
            )}   
        </div>
    );
}

export default Projects;