import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { registerSponsor } from '../../redux/slices/sponsorRegistrationSlice';
import AddressForm from '../forms/AddressForm';
import { useTranslation } from 'react-i18next';


const SponsorRegistration = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrationStatus = useSelector((state) => state.sponsorRegistration.status);
    const registrationError = useSelector((state) => state.sponsorRegistration.error);

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postcode, setPostcode] = useState('');
    const [addressFound, setAddressFound] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        sponsorName: '',
        description: '',
        url: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        logo: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleFileChange = (e) => {
        const logo = e.target.files[0];
        setSelectedFile(logo ? logo.name : t('registration.noLogoChosen'));
        setFormData((prevData) => ({ ...prevData, logo }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submissionData = new FormData();
        for (const key in formData) {
            submissionData.append(key, formData[key]);
        };

        // Append data from AddressForm component
        submissionData.append('addressLine1', addressLine1);
        submissionData.append('addressLine2', addressLine2);
        submissionData.append('city', city);
        submissionData.append('country', country);
        submissionData.append('postcode', postcode);
        submissionData.append('addressFound', addressFound);

        try {
            const actionResult = await dispatch(registerSponsor(submissionData));
            // Check if the registration action succeeded
            if (registerSponsor.fulfilled.match(actionResult)) {
                // Registration successful, navigate to success page
                navigate('/registration-success');
            }
        } catch (error) {
            // Handle registration error
            console.error("Registration error:", error);
        }
    }

    useEffect(() => {
        // Check if registration status is 'succeeded' before clearing the form
        if (registrationStatus === 'succeeded') {
            // Clear form data and reset error message upon successful registration
            setFormData({
                sponsorName: '',
                description: '',
                url: '',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                logo: null,
            });

            // Reset error message
            dispatch({ type: 'registration/resetError' });
        } 

    }, [registrationStatus, dispatch]);

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('registration.registerAsSponsor')}</h2>
                    <p className="text-center">{t('registration.registerToSponsor')}</p>
                    <form onSubmit={handleSubmit}>  
                        <div className="fs-5">{t('registration.company')}</div>
                        <div className="border p-3 mb-3">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sponsorName">{t('registration.sponsorName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="sponsorName"
                                    name="sponsorName"
                                    autoComplete="organization"
                                    value={formData.sponsorName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="description">{t('registration.description')}</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    autoComplete="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                    rows="4"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="url">{t('registration.website')}</label>
                                <input
                                    className="form-control"
                                    type="url"
                                    id="url"
                                    name="url"
                                    autoComplete="url"
                                    value={formData.website}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    pattern="https?://.+"
                                    title="Include http:// or https://"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="logo">{t('registration.logo')}</label>
                                <div className="custom-file-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label className="form-control" htmlFor="logo">
                                        {selectedFile || t('registration.chooseLogo')}
                                    </label>
                                </div>
                            </div>

                            <AddressForm
                                setAddressLine1={setAddressLine1}
                                addressLine2={addressLine2}
                                setAddressLine2={setAddressLine2}
                                setCity={setCity}
                                setCountry={setCountry}
                                setPostcode={setPostcode}
                                setAddressFound={setAddressFound}
                            />
                                                
                        </div>
                        <div className="fs-5">{t('registration.sponsorRepresentative')}</div>
                        <div className="border p-3 mb-3">
                            <div className="row gx-3">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="firstName">{t('registration.firstName')}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            autoComplete="given-name"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="lastName">{t('registration.lastName')}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            autoComplete="family-name"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">{t('registration.email')}</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="password">{t('registration.password')}</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="password"
                                    name="password"
                                    autoComplete="current-password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="confirmPassword">{t('registration.confirmPassword')}</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    autoComplete="current-password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="tos"
                                    name="tos"
                                    required
                                />
                                <label className="form-check-label" htmlFor="tos">
                                    {t('registration.registrationAcknowledge')} 
                                    {' '}
                                    <Link to="/sponsors-terms-of-service"> {t('registration.tos')}</Link>, 
                                    {' '}
                                    <Link to="/privacy-policy">{t('registration.privacyPolicy')}</Link> 
                                    {' '}
                                    {t('general.and')} 
                                    {' '}
                                    <Link to="/cookies-policy">{t('registration.cookiesPolicy')}.</Link>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={registrationStatus === 'loading'}>
                            {registrationStatus === 'loading' ? 'Registering...' : t('registration.register')}
                        </button>
                        {registrationError && <div className="text-danger mt-3">{registrationError}</div>}
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default SponsorRegistration;