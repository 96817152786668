import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchPlansByProjectId } from '../../redux/slices/plansByProjectIdSlice';
import { fetchSubscribedPlans } from '../../redux/slices/subscribedPlansSlice';
import { createCheckoutSession } from '../../redux/slices/createCheckoutSessionSlice';
import { archivePlan } from '../../redux/slices/archivePlanSlice';
import { publishPlan } from '../../redux/slices/publishPlanSlice';
import { removePlan } from '../../redux/slices/removePlanSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

function PlansTable({ projectId, connectedAccountId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const userId = userData?.user?.id;
    const sponsorUser = userData?.user?.user_type === 'sponsor';

    const [message, setMessage] = useState('');
    const [showRemoveArchivePlanConfirmation, setShowRemoveArchivePlanConfirmation] = useState(false);
    const [showPublishPlanConfirmation, setShowPublishPlanConfirmation] = useState(false);
    const [planIdToRemoveArchive, setPlanIdToRemoveArchive] = useState('');
    const [planToRemoveArchive, setPlanToRemoveArchive] = useState({});
    const [planToPublish, setPlanToPublish] = useState({});
    const [isPublishedPlan, setIsPublishedPlan] = useState(false);

    const plansByProjectId = useSelector((state) => state.plansByProjectId.plans);
    const subscribedPlans = useSelector((state) => state.subscribedPlans.subscribedPlans);

    useEffect(() => {
        dispatch(fetchPlansByProjectId(projectId));
        dispatch(fetchSubscribedPlans());
    }, [dispatch, projectId]);

    // Check if the plans are subscribed
    let plansByProjectIdWithSubscriptionStatus
    if (Array.isArray(plansByProjectId)) {
        // Ensure subscribedPlans is an array before mapping
        const subscribedPlanIds = Array.isArray(subscribedPlans)
            ? subscribedPlans.map(plan => plan.plan_id)
            : [];
        plansByProjectIdWithSubscriptionStatus = plansByProjectId?.map(plan => ({
            ...plan,
            is_subscribed: subscribedPlanIds.includes(plan.plan_id)
        }));
    };

    // Add is_cancelled to plansByProjectIdWithSubscriptionStatus
    // Check if all cancel_at are non-null for a specific plan_id
    const plansByProjectIdWithSubscriptionStatusAndWithIsCancelled = plansByProjectIdWithSubscriptionStatus?.map(plan => {
        // Ensure subscribedPlans is an array and get all subscribed plans for this plan_id
        const relatedSubscribedPlans = Array.isArray(subscribedPlans) 
            ? subscribedPlans?.filter(sub => sub.plan_id === plan.plan_id) 
            : [];
        // Check if all cancel_at values are non-null
        const isCancelled = relatedSubscribedPlans.length > 0 && relatedSubscribedPlans.every(sub => sub.cancel_at && new Date(sub.cancel_at) < new Date());
        // Add is_cancelled to the plan
        return {
            ...plan,
            is_cancelled: isCancelled
        };
    });

    let isOwner = false;
    let sortedPlans = [];
    let sortedBenefits = {};
    let planPriceDetails = {};
    let uniqueBenefits = [];
    let benefitMap = {};
    if (Array.isArray(plansByProjectId)) {
        isOwner = plansByProjectId?.some(plan => plan.user_id === userId);
   
        // Sort benefits by price and then by id and remove duplicates
        // Step 1: Sort the plans by first_price (convert string to number)
        sortedPlans = [...plansByProjectId].sort((a, b) => parseFloat(a.first_price) - parseFloat(b.first_price));

        // Step 2: For each plan, sort its benefits by benefit_id
        sortedBenefits = sortedPlans
            ?.filter(plan => !plan.plan_archived) // Exclude archived plans
            .filter(plan => plan.plan_published || isOwner) // Exclude unpublished plans for non-owners
            .flatMap(plan => {
            // Sort the benefits within each plan
            const sortedPlanBenefits = [...plan.plan_benefits].sort((a, b) => a.benefit_id - b.benefit_id);
            // Attach the plan's price to each benefit (to use later in rendering)
            return sortedPlanBenefits.map(benefit => ({
                ...benefit,
                plan_price: plan.first_price, // Include plan price for rendering
                plan_name: plan.plan_name,
                stripe_lookup_key: plan.plan_prices[0].stripe_lookup_key
            }));
        });

        // Step 3: Iterate through the sortedPlanBenefits array
        sortedBenefits.forEach(benefit => {
            const { description, plan_name } = benefit;

            // If the benefit with the same description is already in benefitMap, just add the plan_name
            if (benefitMap[description]) {
                benefitMap[description].plans.push(plan_name);
            } else {
                // Create a new entry for this benefit description and add it to result
                benefitMap[description] = { ...benefit, plans: [plan_name] };
                uniqueBenefits.push(benefitMap[description]);
            }
        });
        
        // Map to include plan_name, plan_id, and stripe_lookup_key
        planPriceDetails = plansByProjectIdWithSubscriptionStatusAndWithIsCancelled.flatMap(plan => ({
            plan_name: plan.plan_name,
            plan_id: plan.plan_id,
            plan_price: plan.plan_prices[0].plan_price,
            stripe_lookup_key: plan.plan_prices[0].stripe_lookup_key,
            is_subscribed: plan.is_subscribed,
            is_cancelled: plan.is_cancelled,
            plan_archived: plan.plan_archived,
            plan_published: plan.plan_published
        })) 
    };

    const handleRemoveArchivePlan = async (planId) => {
        setPlanIdToRemoveArchive(planId); // no need to await
        const plan = plansByProjectIdWithSubscriptionStatus.find(({ plan_id }) => plan_id === planId); 
        setIsPublishedPlan(plan.plan_published);
        setPlanToRemoveArchive(plan);
        setShowRemoveArchivePlanConfirmation(true);
    };

    const cancelRemoveArchivePlan = () => {
        setShowRemoveArchivePlanConfirmation(false);
    };

    const confirmRemoveArchivePlan = async () => {
        if (isPublishedPlan) {
            await dispatch(archivePlan(planIdToRemoveArchive));
        } else {
            await dispatch(removePlan(planIdToRemoveArchive));
        }
        
        await dispatch(fetchPlansByProjectId(projectId));
        await setShowRemoveArchivePlanConfirmation(false);
    };

    const handlePublishPlan = async (planId) => {
        const plan = plansByProjectId.find(({ plan_id }) => plan_id === planId);
        const formattedPlan = {
            planId: plan.plan_id,
            projectId: plan.project_id,
            planName: plan.plan_name,
            planPrice: plan.plan_prices[0].plan_price,
            projectName: plan.project_name
        }
        setPlanToPublish(formattedPlan);
        setShowPublishPlanConfirmation(true);
    };

    const cancelPublishPlan = () => {
        setShowPublishPlanConfirmation(false);
    };

    const confirmPublishPlan = async () => {
        await dispatch(publishPlan(planToPublish));
        await dispatch(fetchPlansByProjectId(projectId));
        await setShowPublishPlanConfirmation(false);
    };

    const isPlanSubscribedByAuthSponsor = (planId) => {
        if (Array.isArray(subscribedPlans)) {
            return subscribedPlans?.some(plan => plan.user_id === userId && plan.plan_id === planId);
        }
    };

    const isPlanCancelledByAuthSponsor = () => {
        if (Array.isArray(subscribedPlans)) {
            return subscribedPlans.some(plan => plan.cancel_at && new Date(plan.cancel_at) < new Date());
        }
    };

    const handleSponsorNow = async (stripeLookupKey, planId) => {
        if ((userId && sponsorUser && !isPlanSubscribedByAuthSponsor(planId)) || (userId && sponsorUser && isPlanCancelledByAuthSponsor(planId))) {
            try {
                const response = await dispatch(createCheckoutSession({ userId, connectedAccountId, stripeLookupKey })).unwrap();
                window.location.href = response.url; // Redirect to the Stripe checkout page
              } catch (error) {
                console.error('Error creating checkout session:', error);
                // Handle error appropriately
              }
        } else if (userId && sponsorUser && isPlanSubscribedByAuthSponsor(planId)){
            setMessage(t('plans.planAlreadySubscribed'));
        } else if(!userId) {
            navigate('/login');
        } else {
            setMessage(t('plans.warning'));
        }
    };

    const visiblePlans = planPriceDetails.length > 0 && 
        !planPriceDetails
        .filter(plan => plan.plan_published || isOwner)
        .every(plan => plan.plan_archived);

    return (
        <>
        {visiblePlans ? (
            <>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th rowSpan="2">{t('plans.description')}</th>
                        {planPriceDetails
                        .filter(plan => !plan.plan_archived)
                        .filter(plan => plan.plan_published || isOwner)
                        .map((plan, index) => (
                            <th key={index} className="text-center fs-3 text-sponsify-blue">
                                {plan.plan_name}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {planPriceDetails
                        .filter(plan => !plan.plan_archived)
                        .filter(plan => plan.plan_published || isOwner)
                        .map((plan, index) => (
                            <th key={index} className="text-center fs-5 fw-bold text-sponsify-blue">
                                €{plan.plan_price}<span className="fs-6 fw-normal">/{t('general.mo')}</span><br/>
                                <span className="fw-light fs-6">(+ IVA)</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {uniqueBenefits.map(benefit => (
                            <tr key={benefit.benefit_id}>
                                <td>{benefit.description}</td>
                                {planPriceDetails
                                .filter(plan => !plan.plan_archived)
                                .filter(plan => plan.plan_published || isOwner)
                                .map(planPriceDetail => (
                                    <td key={planPriceDetail.plan_id} className="text-center">
                                        {benefit.plans.some(plan => (plan === planPriceDetail.plan_name)) ? 
                                        <FontAwesomeIcon className="text-primary" icon="fa-solid fa-check" /> : <FontAwesomeIcon className="text-sponsify-red" icon="fa-solid fa-xmark" />}
                                    </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        {planPriceDetails
                        .filter(plan => !plan.plan_archived)
                        .filter(plan => plan.plan_published || isOwner)
                        .map(plan => (
                            <td key={plan.plan_id} className="text-center">
                                {isOwner ? (
                                    <Link  
                                        to={`/update-plan/${plan.plan_id}`}
                                        type="button" 
                                        className={`${plan.is_subscribed ? 'disabled' : '' } btn btn-sm btn-outline-primary btn-block text-capitalize w-100`}
                                    >
                                        {t('buttons.edit')}
                                    </Link>
                                ) : (
                                    <button  
                                        onClick={() => handleSponsorNow(plan.stripe_lookup_key, plan.plan_id)} 
                                        type="button" 
                                        className="btn btn-lg btn-block btn-primary text-uppercase"
                                    >
                                        {t('buttons.sponsorNow')}
                                    </button>
                                )}
                            </td>       
                        ))}
                    </tr>
                    {isOwner && (
                        <>
                        <tr>
                            <td></td>
                            {planPriceDetails
                            .filter(plan => !plan.plan_archived)
                            .filter(plan => plan.plan_published || isOwner)
                            .map(plan => (
                                <td key={plan.plan_id} className="text-center">
                                    <button 
                                        onClick={() => handlePublishPlan(plan.plan_id)}
                                        className={`${plan.plan_published ? 'disabled' : '' } btn btn-sm btn-outline-primary btn-block text-capitalize w-100`}
                                        type="button" 
                                    >
                                        {plan.plan_published ? t('buttons.published') : t('buttons.publish') }
                                    </button> 
                                </td>       
                            ))}
                        </tr>
                        <tr>
                            <td></td>
                            {planPriceDetails
                            .filter(plan => !plan.plan_archived)
                            .filter(plan => plan.plan_published || isOwner)
                            .map(plan => (
                                <td key={plan.plan_id} className="text-center">
                                    <button 
                                        onClick={() => handleRemoveArchivePlan(plan.plan_id)}
                                        className={`${plan.plan_archived ? 'disabled' : '' } btn btn-sm btn-outline-primary btn-block text-capitalize w-100`}
                                        type="button" 
                                    >
                                        {plan.plan_published ? t('buttons.archive') : t('buttons.remove') }
                                    </button> 
                                </td>       
                            ))}
                        </tr>
                        </>    
                    )}
                </tfoot>       
            </table>

            <Modal show={showRemoveArchivePlanConfirmation} >
                <Modal.Header closeButton onClick={cancelRemoveArchivePlan}>
                    <Modal.Title className="text-capitalize">  
                        {planToRemoveArchive && !isPublishedPlan ? (
                            t('plans.confirmPlanRemoval')
                        ) : (
                            t('plans.confirmPlanArchiving')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {planToRemoveArchive && !isPublishedPlan ? (
                        <p>{t('plans.removePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    ) : (
                        <p>{t('plans.archivePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelRemoveArchivePlan}>{t('buttons.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={confirmRemoveArchivePlan}>{t('buttons.confirm')}</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPublishPlanConfirmation} >
                <Modal.Header closeButton onClick={cancelPublishPlan}>
                    <Modal.Title className="text-capitalize">{t('plans.confirmPlanPublication')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {planToPublish ? (
                        <p>{t('plans.publishPlanModalMessage')} <strong>{planToPublish.planName}</strong>?</p>
                    ) : (
                        <p>Loading plan details...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelPublishPlan}>{t('buttons.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={confirmPublishPlan}>{t('buttons.confirm')}</button>
                </Modal.Footer>
            </Modal>
            </>
        ) : (
            <div className="container">{t('plans.sponsorshipPlanDisplay')}</div>
        )}
        {message && <div className="alert alert-warning">{message}</div>}
        </>
    );
};

export default PlansTable;