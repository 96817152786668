import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchGranteeByUserId } from '../../redux/slices/granteeByUserIdSlice';
import { updateGrantee, resetStatus } from '../../redux/slices/updateGranteeSlice';
import { useTranslation } from 'react-i18next';


const UpdateGrantee = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userId } = useParams();

    const updateGranteeStatus = useSelector((state) => state.updateGrantee.status);
    const updateGranteeError = useSelector((state) => state.updateGrantee.error);
    const grantee = useSelector((state) => state.granteeByUserId.granteeByUserId);

    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        granteeType: '', 
        organizationName: '', 
        organizationDescription: '', 
        firstName: '',
        lastName: '',
        url: '',
        facebook: '', 
        instagram: '',
        x: '',
        linkedin: '',
        pinterest: '',
        tiktok: '',
        youtube: '',
        twitch: ''
    });

    useEffect(() => {
        if (userId) {
            dispatch(fetchGranteeByUserId(userId));
        }
    }, [dispatch, userId]);

    // Pre-fill the form with grantee data
    useEffect(() => {
        if (grantee) {
            setFormData({
                granteeType: grantee.grantee_type || '', 
                organizationName: grantee.organization_name || '', 
                organizationDescription: grantee.organization_description || '', 
                firstName: grantee.first_name ||'',
                lastName: grantee.last_name ||'',
                url: grantee.grantee_ural ||'',
                facebook: grantee.grantee_facebook ||'', 
                instagram: grantee.grantee_instagram ||'',
                x: grantee.grantee_x ||'',
                linkedin: grantee.grantee_linkedin ||'',
                pinterest: grantee.grantee_pinterest ||'',
                tiktok: grantee.grantee_tiktok ||'' ,
                youtube: grantee.grantee_youtube ||'' ,
                twitch: grantee.grantee_twitch ||'' 
            });
        }
    }, [grantee]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleFileChange = (e) => {
        const logo = e.target.files[0];
        setSelectedFile(logo ? logo.name : t('registration.noLogoChosen'));
        setFormData((prevData) => ({ ...prevData, logo }));
    };

    const handleGranteeTypeChange = (e) => {
        const selectedGranteeType = e.target.value;
        setFormData((prevData) => ({ ...prevData, granteeType: selectedGranteeType }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object 
        const submissionData = new FormData();

        for (const key in formData) {
            submissionData.append(key, formData[key]);
        }

        // Append others data
        submissionData.append('username', grantee.username);
        if (grantee.grantee_logo !== null && grantee.grantee_logo !== undefined) {
            submissionData.append('existingLogo', grantee.grantee_logo);
        }

        // Dispatch action to update project
        await dispatch(updateGrantee({ userId, submissionData }));
    };

    useEffect(() => {
        // Redirect only if the grantee updating succeeds
        if (updateGranteeStatus === 'succeeded') {
            navigate('/projects');
            dispatch(resetStatus());
        }
    }, [updateGranteeStatus, dispatch, navigate]);

    const socialMediaPlatforms = [
        { id: "facebook", name: "Facebook", domain: "facebook.com" },
        { id: "instagram", name: "Instagram", domain: "instagram.com" },
        { id: "x", name: "X", domain: "x.com" },
        { id: "linkedin", name: "LinkedIn", domain: "linkedin.com" },
        { id: "pinterest", name: "Pinterest", domain: "pinterest.com" },
        { id: "tiktok", name: "TikTok", domain: "tiktok.com" },
        { id: "youtube", name: "YouTube", domain: "youtube.com" },
        { id: "twitch", name: "Twitch", domain: "twitch.tv" },
    ];

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">

                    <h2 className="text-center mb-5">{t('grantees.editProfile')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <p className="mb-2">{t('grantees.granteeType')}</p>
                            <select 
                                className="form-select" 
                                aria-label="Select the grantee type" 
                                value={formData.granteeType} 
                                onChange={handleGranteeTypeChange}
                                required
                            >
                                <option value="individual">{t('registration.individual')}</option>
                                <option value="organization">{t('registration.organization')}</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="firstName">{t('registration.firstName')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="firstName"
                                name="firstName"
                                autoComplete="given-name"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="lastName">{t('registration.lastName')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="lastName"
                                name="lastName"
                                autoComplete="family-name"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {formData.granteeType === 'organization' && (
                            <> 
                            <div className="mb-3">
                                <label className="form-label" htmlFor="organizationName">{t('registration.organizationName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="organizationName"
                                    name="organizationName"
                                    autoComplete="organization"
                                    value={formData.organizationName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                            <label className="form-label" htmlFor="organizationDescription">{t('registration.organizationDescription')}</label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    id="organizationDescription"
                                    name="organizationDescription"
                                    autoComplete="description"
                                    value={formData.organizationDescription}
                                    onChange={handleChange}
                                    required
                                    rows="4"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="url">{t('registration.website')}</label>
                                <input
                                    className="form-control"
                                    type="url"
                                    id="url"
                                    name="url"
                                    autoComplete="url"
                                    value={formData.url}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    pattern="https?://.+"
                                    title="Include http:// or https://"
                                />
                            </div>
                            </>
                        )}

                        {/* Social media */}
                        {socialMediaPlatforms.map((platform) => (
                            <div className="mb-3" key={platform.id}>
                                <label className="form-label" htmlFor={platform.id}>
                                {platform.name}
                                </label>
                                <input
                                    className="form-control"
                                    type="url"
                                    id={platform.id}
                                    name={platform.id}
                                    placeholder={`https://${platform.domain}/yourpage`}
                                    autoComplete="url"
                                    value={formData[platform.id] || ""}
                                    onChange={handleChange}
                                    pattern={`https?://(www\\.)?${platform.domain}/.+`}
                                    title={`Include a valid ${platform.name} URL (e.g., https://${platform.domain}/yourpage)`}
                                />
                            </div>
                        ))}
                        
                        {formData.granteeType === 'organization' ? (
                            <div className="mb-3">
                                <label className="form-label" htmlFor="logo">{t('registration.logo')}</label>
                                <div className="custom-file-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label className="form-control" htmlFor="logo">
                                        {selectedFile || t('updating.changeLogo')}
                                    </label>
                                </div>
                            </div>
                        ):(
                            <div className="mb-3">
                                <label className="form-label" htmlFor="logo">{t('registration.profilePicture')}</label>
                                <div className="custom-file-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label className="form-control" htmlFor="logo">
                                        {selectedFile || t('updating.changeProfilePicture')}
                                    </label>
                                </div>
                            </div>
                        )}
                        
                        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={updateGranteeStatus === 'loading'}>
                            {updateGranteeStatus === 'loading' ? 'Updating Grantee...' : t('buttons.submit')}
                        </button>
                        {updateGranteeError && <div className="text-danger mt-3">{updateGranteeError}</div>}
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default UpdateGrantee;